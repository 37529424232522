import React from "react";
import { useState } from "react";
import '../pages/App.css';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import QrCodeScanner from './qrCodeScanner';
import ResultContainerPlugin from './qrCodeResults';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import KeyboardIcon from '@mui/icons-material/Keyboard';

export function Search() {
  const [scanQrCode, setScanQrCode] = useState(false);
  const [decodedResults, setDecodedResults] = useState([]);
  const onNewScanResult = (decodedText, decodedResult) => {
      console.log("App [result]", decodedResult);
      setDecodedResults(prev => [...prev, decodedResult]);
  };

  function startQrScanner() {
    setScanQrCode(true);
  }

  function stopQrScanner() {
    setScanQrCode(false);
  }
  
    return (
<>
{ scanQrCode ? (
  <>
  <ResultContainerPlugin results={decodedResults} />
<QrCodeScanner
                    fps={10}
                    qrbox={250}
                    disableFlip={false}
                    qrCodeSuccessCallback={onNewScanResult}
                />
                <br />
                <Button
                            margin="dense"
                            variant="contained"
                            onClick={stopQrScanner}
                            startIcon={<KeyboardIcon />}
                        >
                            Return to Search
                        </Button>
</>
): null }
{ !scanQrCode ? (
  <>

                <Button
                            margin="dense"
                            variant="contained"
                            onClick={startQrScanner}
                            startIcon={<QrCode2Icon />}
                        >
                            Scan Item QR
                        </Button>
<Box
  sx={{
    display: 'flex',
    flexDirection: 'column',
  }}
  autoComplete="off"
>
  <TextField 
  fullWidth
    margin="dense"
    id="itemInput" 
    label="Search" 
    variant="outlined" 
    type="text" 
    onKeyDown={(e) => (
      e.keyCode === 13 ?  window.location.href='/?item='+ document.getElementById("itemInput").value : null
    )}
  />
  </Box>
<Button variant="contained" onClick={(e) => { window.location.href='/?item='+ document.getElementById("itemInput").value}}>Search</Button>
</>
  ): null }
</>
  )}

export default Search