import React, { useState, useEffect } from "react";
import { baseUrl } from '../../shared.js';
import '../App.css';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
  
function Edit({foundItem}) {
  const [changed, setChanged] = useState(false);
  const [itemEdit] = useState(foundItem);
  const [modItem, setModItem] = useState(foundItem);
  var [catNoList, setCatNoList] = useState([]);
  const [catNoListLoaded, setCatNoListLoaded] = useState(false);
  var [newItemKey, setNewItemKey] = useState();

  
useEffect(()=> {
  console.log(changed);
  console.log('Edit Item ',itemEdit);
  console.log('Mod Item ',modItem);
});

const [open, setOpen] = React.useState(false);
const [responseOpen, setResponseOpen] = React.useState(false);
const [message, setMessage] = useState('');


const handleResponseClose = () => {
setResponseOpen(false);
window.location.href='/?item='+[modItem.itemNo]
};


    const handleClickOpen = () => {
      setOpen(true);
    };

  const handleClose = () => {
    setOpen(false);
  };

  const loadCatNoList = async () => {
      const url = baseUrl + 'catNo/list';
        await fetch(url, {
          method: 'GET',
          headers: {
              'username': localStorage.getItem('username'),
              'bToken': localStorage.getItem('bToken')
          }})
          .then((res) => {
            return res.json();
        }).then((json) => {
            setCatNoList(json);
            console.log('CatNo List Loaded ', catNoList)
        })
        .catch((err) => {
          setCatNoListLoaded(false);
           console.log(err.message);
        });
      }
            
      useEffect(() => {
        if (!catNoListLoaded ) {
          setCatNoListLoaded(true)
          loadCatNoList()
        }
      })
  

async function updateItem(e) {
  e.preventDefault();
  const url = baseUrl+'id/'+ [itemEdit.id];
  await fetch(url, {
      method: 'PATCH',
      headers: {
          'Content-Type': 'application/json',
          'username': localStorage.getItem('username'),
          'bToken': localStorage.getItem('bToken'),
          'editType': 'Update'
      },
      body: JSON.stringify(modItem),
  })
      .then((response) => response.json())
        .then((data) => {
          console.log(data)
          if( data.status === 200) {
          setMessage(data.message);
          setResponseOpen(true);
          } else if (data.status === 401) {
            setMessage(data.message);
            setResponseOpen(true);
          }
        })
        .catch((err) => {
          setMessage(err.message);
          setResponseOpen(true);
        });
}

async function deleteItem(e) {
  e.preventDefault();
  const url = baseUrl+'id/'+ [itemEdit.id];
  await fetch(url, {
      method: 'DELETE',
      headers: {
          'Content-Type': 'application/json',
          'username': localStorage.getItem('username'),
          'bToken': localStorage.getItem('bToken')
      },
  })
      .then((data) => {
        window.location.href='/'
      });
}

return (
    <div>
      <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="confirmDeleteTitle">
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="confirmDeleteDesc">
            Are you sure you want to delete the Item?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={deleteItem}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
      <React.Fragment>
      <Dialog
        open={responseOpen}
        onClose={handleResponseClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="confirmResponseTitle">
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="confirmResponseDesc">
            [{message}]
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleResponseClose}>OK</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>

        <div>
     <Box 
          margin="dense">
         <TextField 
        fullWidth
          margin="dense"
          id="itemNo" 
          label="Item Number" 
          variant="outlined" 
          type="text" 
          InputProps={{
            readOnly: true,
          }}
          value={modItem.itemNo} 
          onChange={(e) => {
            setChanged(true);
            setModItem({
               ...modItem,
            itemNo: e.target.value,
            });
            }}
        />
         <TextField 
        fullWidth
          margin="dense"
          id="name" 
          label="Name" 
          variant="outlined" 
          type="text" 
          value={modItem.name} 
          onChange={(e) => {
            setChanged(true);
            setModItem({
               ...modItem,
            name: e.target.value,
            });
            }}
        />
        <FormControl
             margin="dense"
        fullWidth>
        <Autocomplete
        required
        defaultValue={modItem.linkedCatNo} 
     disablePortal
     disableClearable
     id={newItemKey}
     options={catNoList}
     onChange={(e) => {
       setChanged(true);
       setModItem({
          ...modItem,
       linkedCatNo: e.target.value,
       });
       setNewItemKey(`${Math.floor((Math.random() * 1000))}-min`);
       }}
       renderInput={(params) => <TextField {...params} label="Linked CatNo" />}
       />
     </FormControl>
     </Box>
     
{changed ? ( <form
                        className="w-full max-w-sm"
                        id="item"
                        onClick={updateItem}
                    >
                     <br></br>
                        <div className="mb-2">
                            <Button variant="contained"
                                form="item"
                            >
                                Save Changes
                            </Button>
                        </div>
                        </form>
                        
                    ) : null
                  }
<br></br>

                     <form
                        className="w-full max-w-sm"
                        id="itemDelete"
                    >
                    <div className="mb-2">
                            <Button variant="contained"
                                onClick={(e) => {
                                    window.location.href='/?item='+[modItem.itemNo]
                                }}
                            >
                                Cancel Editing
                            </Button>
                            &nbsp;&nbsp;&nbsp;  
                            { localStorage.getItem('adminFunctions') === '1' ? (
                            <Button variant="contained"
                                form="itemDelete"
                                className="bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded"
                                onClick={handleClickOpen}
                            >
                                Delete Item
                            </Button>
                            ): null}
                            </div>
                        </form>
        </div>
  </div>
)};
                              
export default Edit