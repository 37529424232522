import React, { useEffect, useState } from "react";
import  { useNavigate, Navigate } from 'react-router-dom'
import { baseUrl } from "../shared";
import '../pages/App.css';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export function Register() {
    const [usernameReg, setUsernameReg] = useState("");
    const [passwordReg, setPasswordReg] = useState ("");
    const [companyReg, setCompanyReg] = useState ("");
    const [fullNameReg, setFullNameReg] = useState ("");
    const [emailAddressReg, setEmailAddressReg] = useState ("");
    const [canAdminFunctions, setCanAdminFunctions] = useState ("0");
    const navigate = useNavigate();
    
    const marks = [
      {
        value: 0,
        label: 'No',
      },
      {
        value: 1,
        label: 'Yes',
      },
    ];

    
    const [loginStatus, setLoginStatus] = useState(false);
    const [createNewUser] = useState(true);
    const [open, setOpen] = React.useState(false);
    const [errOpen, setErrOpen] = React.useState(false);
    const [message, setMessage] = useState('');

    const handleClose = () => {
      setOpen(false);
      <Navigate to={'/admin/'} />
      {navigate(0)}
    };

    const handleErrClose = () => {
      setErrOpen(false);
    };

    useEffect(()=> {
      if (!localStorage.getItem('token')) {
        setLoginStatus(false);
      } else {
        setLoginStatus(true);
      }
    },[]);

  const cancelCreateUser = () => {
    <Navigate to={'/admin/'} />
    {navigate(0)}
  };
  
  const sendCreateUser = async () => {   
    const url = baseUrl+"user/register";
    var payload = {
      "username": usernameReg,
      "password": passwordReg,
      "fullName": fullNameReg,
      "company": companyReg,
      "emailAddress": emailAddressReg,
      "active": "1",
      "adminFunctions": canAdminFunctions,
    }
    await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'username': localStorage.getItem('username'),
            'bToken': localStorage.getItem('bToken')
        },
        body: ( "json", JSON.stringify( payload ) )
      })
      .then((response) => response.json())
        .then((data) => {
          console.log(data)
          if( data.status === 200) {
          setMessage(data.message);
          setOpen(true);
          } else if (data.status === 401) {
            setMessage(data.message);
            setErrOpen(true);
          }
        })
        .catch((err) => {
          setMessage(err.message);
          setOpen(true);
        });
};

  
    return (
      <div className="admin">
        <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="confirmDeleteTitle">
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="confirmDeleteDesc">
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Ok</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
        <React.Fragment>
      <Dialog
        open={errOpen}
        onClose={handleErrClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="addAccountError">
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="addAccountDesc">
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleErrClose}>Ok</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
    

    { loginStatus && createNewUser && localStorage.getItem('adminFunctions') === '1' ? (
        <form className="registration"
        >
          <p>Register New User</p>
        <Box>
         <TextField 
        fullWidth
        required
          margin="dense"
          id="username" 
          label="Username" 
          variant="outlined" 
          type="text"  
          onChange={(e) => {
            setUsernameReg(e.target.value);
          }}
        />
     </Box>
        <Box>
         <TextField 
        fullWidth
        required
          margin="dense"
          id="password" 
          label="Password" 
          variant="outlined" 
          type="password"  
          onChange={(e) => {
            setPasswordReg(e.target.value);
          }}
        />
     </Box>
        <Box>
          <TextField
          fullWidth
          required
          label="Company"
          variant="outlined" 
          type="text"  
          onChange={(e) => {
            setCompanyReg(e.target.value);
          }}
          />
            </Box>
        <Box>
         <TextField 
        fullWidth
        required
          margin="dense"
          id="name" 
          label="Full Name" 
          variant="outlined" 
          type="text"  
          onChange={(e) => {
            setFullNameReg(e.target.value);
          }}
        />
     </Box>
        <Box>
         <TextField 
        fullWidth
        required
          margin="dense"
          id="email" 
          label="E-Mail Address" 
          variant="outlined" 
          type="text"  
          onChange={(e) => {
            setEmailAddressReg(e.target.value);
          }}
        />
     </Box>
     <Box  sx={{ maxWidth: '85%' }}>
     <Typography left>Can Use Admin Functions</Typography>
     <Slider 
  size="small"
  defaultValue={0}
  step={1}
  min={0}
  max={1}
  marks={marks}
  valueLabelDisplay="off"
  onChange={(e) => {
    setCanAdminFunctions(e.target.value);
  }}
/>
</Box>
          <Button variant="contained" margin="dense" onClick={sendCreateUser}> Register</Button>
          
          <Button variant="contained" margin="dense" onClick={cancelCreateUser}>Cancel User Creation</Button>
        </form>
        
        ) : null
      }
      <br />
      <br />
      </div>
    );
  }

export default Register