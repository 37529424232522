import React from 'react';
import LockResetIcon from '@mui/icons-material/LockReset';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';

export const userBarData = [
  {
    title: 'Reset Password',
    path: '/resetPassword',
    icon: <LockResetIcon />,
    cName: 'user-text'
  },
  {
    title: 'Logout',
    path: '/logout',
    icon: <MeetingRoomIcon />,
    cName: 'user-text'
  }
];