import React, { useEffect, useState } from "react";
import { baseUrl } from "../shared";
import '../pages/App.css';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import OutlinedInput from '@mui/material/OutlinedInput';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';

export function Login() {
  
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState ("");
    
    const [loginStatus, setLoginStatus] = useState(false);
    const [showPassword, setShowPassword] = React.useState(false);

    const [serverErrorLogin, setServerErrorLogin] = useState(true);
    const [invalidCredsLogin, setInvalidCredsLogin] = useState(true);
    const [emptyCredsLogin, setEmptyCredsLogin] = useState(true);
    const [serverErrorLoginKey, setServerErrorLoginKey] = useState();
    
    var passwordFailMessage;

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
    event.preventDefault();}

    useEffect(()=> {
      if (!localStorage.getItem('token')) {
        setLoginStatus(false);
      } else {
        setLoginStatus(true);
      }
    },[]);
  
    const login = () => {
      if(password === '' || username === '') {
        setEmptyCredsLogin(false);
        setInvalidCredsLogin(true);
        setServerErrorLogin(true);
        // setServerErrorLoginKey(`${Math.floor((Math.random() * 1000))}-min`);
      } else {
        console.log('login');
    const url = baseUrl+"user/login";
    var payload = {
      "username": username,
      "password": password,
    }
    fetch(url, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',            
        },
        body: ( "json", JSON.stringify( payload ) )
      })
        .then((response) => response.json())
      .then((data) => {
        console.log(data)
        if (data === {}) {
          setLoginStatus(false);
          setEmptyCredsLogin(true);
          setInvalidCredsLogin(false);
          setServerErrorLogin(true);
          // setServerErrorLoginKey(`${Math.floor((Math.random() * 1000))}-min`);
        } else {
          console.log("login: ",data);
          localStorage.setItem("token", data.token)
          localStorage.setItem("username", data.result.username)
          localStorage.setItem("bToken", data.bToken)
          localStorage.setItem("fullName", data.result.fullName)
          localStorage.setItem("adminFunctions", data.result.adminFunctions)
          setLoginStatus (true);
          window.location.href='/'
        }
      }).catch((error) => {
        setLoginStatus(false);
        setEmptyCredsLogin(true);
        setInvalidCredsLogin(true);
        setServerErrorLogin(false);
        // setServerErrorLoginKey(`${Math.floor((Math.random() * 1000))}-min`);
     });;
    }
    };
  
    return (
      <div className="login">
      <Box 
           margin="dense">
          <p>Login</p>
          <br />
            <TextField
                fullWidth
                label="Username" 
                variant="outlined" 
                type="text" 
                onChange = { (e) => {
                    setUsername (e.target.value);
                }}
            />
            <FormControl fullWidth margin="dense" variant="outlined">
          <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            type={showPassword ? 'text' : 'password'}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            onChange = { (e) => {
              setPassword (e.target.value);
          }}
          onKeyDown={(e) => (
            e.keyCode === 13 ? login(e) : null
          )}
            label="Password"
          />
           <div key={serverErrorLoginKey} hidden={emptyCredsLogin}><p className="warningText">Please enter a Username and Password to Login.</p></div>
           <div key={serverErrorLoginKey} hidden={invalidCredsLogin}><p className="warningText">Invalid Username/Password Combination Entered!</p></div>
           <div key={serverErrorLoginKey} hidden={serverErrorLogin}><p className="warningText">Server Error, Please try again later.</p></div>
        </FormControl>
          <Button variant="contained" onClick={login} style={{float: 'right'}}>Login</Button>
     </Box>
      </div>
    );
  }

export default Login