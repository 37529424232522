import React, { useEffect, useState } from "react";
import  { useNavigate, Navigate } from 'react-router-dom'
import { baseUrl } from "../shared";
import '../pages/App.css';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import OutlinedInput from '@mui/material/OutlinedInput';
import IconButton from '@mui/material/IconButton';

export function ResetUserPassword() {
    const [changed, setChanged] = useState(false);
    var [userEdit, setUserEdit] = useState({});
    var [modUser, setModUser] = useState({});
    var [userList, setUserList] = useState([]);
    const [usersLoaded, setusersLoaded] = useState(false);
    const [userSelected, setUserSelected] = useState(false);
    const navigate = useNavigate();
    const [loginStatus, setLoginStatus] = useState(false);
    const [updateUser] = useState(true);
    const [open, setOpen] = React.useState(false);
    const [responseOpen, setResponseOpen] = React.useState(false);
    const [message, setMessage] = useState('');

    const [showPassword, setShowPassword] = React.useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
    event.preventDefault();}

    const handleClose = () => {
      setOpen(false);
    };

    const cancelResetUserPassword = () => {
      <Navigate to={'/admin/'} />
      {navigate(0)}
    };

    useEffect(()=> {
      if (!localStorage.getItem('token')) {
        setLoginStatus(false);
      } else {
        setLoginStatus(true);
      }
    },[]);

    useEffect(() => {
      if (usersLoaded === false ) {
      const url = baseUrl + 'user/list/';
        fetch(url, {
          method: 'GET',
          headers: {
              'username': localStorage.getItem('username'),
              'bToken': localStorage.getItem('bToken')
          }})
          .then((res) => {
            return res.json();
        }).then((json) => {
            console.log(json)
            setUserList(json);
            setusersLoaded(true);
        })
        .catch((err) => {
           console.log(err.message);
        });
      }
      })

    useEffect(()=> {
        console.log(changed);
        console.log('Selected User ',userEdit);
        console.log('Modified User ',modUser);
    });

    async function userPasswordReset(e) {
        e.preventDefault();
        const url = baseUrl+'user/resetPassword';
        await fetch(url, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'username': localStorage.getItem('username'),
                'bToken': localStorage.getItem('bToken'),
                'selUserId': modUser.id
            },
            body: JSON.stringify(modUser),
        })
        .then((response) => response.json())
          .then((data) => {
            console.log(data[0])
            if( data[0].status === 200) {
            setMessage(data[0].message);
            setOpen(true);
            } else if (data[0].status === 401) {
              setMessage(data[0].message);
              setResponseOpen(true);
            }
          })
          .catch((err) => {
            setMessage(err.message);
            setOpen(true);
          });
      }

    function userDetails(e) {
      const url = baseUrl+'user/details';
      fetch(url, {
          method: 'PATCH',
          headers: {
              'Content-Type': 'application/json',
              'username': localStorage.getItem('username'),
              'bToken': localStorage.getItem('bToken'),
              'selUserId': e
          },
          body: JSON.stringify(modUser),
      })
          .then((response) => {
              if (!response.ok) throw new Error('something went wrong');
              return response.json();
          })
          .then((data) => {
            setUserEdit(data[0]);
            setModUser(data[0]);
          });
    }

  
    return (
      <div className="admin">
        <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="confirmDeleteTitle">
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="confirmDeleteDesc">
            Confirm Update User
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Ok</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
        <React.Fragment>
      <Dialog
        open={responseOpen}
        onClose={cancelResetUserPassword}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelResetUserPassword}>Ok</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>

    { loginStatus && updateUser && localStorage.getItem('adminFunctions') === '1' ? (
        <form className="registration">
          <p>Update Existing User</p>
          <FormControl
          margin="dense"
     fullWidth>
     <InputLabel shrink htmlFor="nTitle">
    UserName
  </InputLabel>
        <Select
          native
          label="Username"
          inputProps={{
            id: 'usernameTitle',
          }}
          fullWidth
          onChange={(e) => {
            setUserSelected(true);
            setModUser({
              ...modUser,
              selUserId: e.target.value,
           });
            userDetails(e.target.value);
            }}>
            <option selected disabled>Select Username to Modify</option>
          {
          userList.map((userList) => {
          return <option value={userList.id} key={userList.id}>{userList.username}</option>
        })
      }
  </Select>
     </FormControl>

     { userSelected ? (
      <>
      <Box>
      <FormControl fullWidth margin="dense" variant="outlined">
          <InputLabel htmlFor="outlined-adornment-password">New Password</InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            type={showPassword ? 'text' : 'password'}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            onChange={(e) => {
              setChanged(true);
              setModUser({
                 ...modUser,
                 password: e.target.value,
              });
              }}
            label="New Password"
          />
        </FormControl>
        </Box>
</>
      ) : null}
          <Button variant="contained" margin="dense" onClick={cancelResetUserPassword}> Cancel</Button>&nbsp;&nbsp;
          
          {changed ? (  <Button variant="contained" margin="dense" onClick={userPasswordReset}>Reset User Password</Button> ): null}
        </form>
        
        ) : null
      }
      <br />
      <br />
      </div>
    );
  }

export default ResetUserPassword