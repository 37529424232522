import React, { useState, useEffect } from "react";
import { baseUrl } from '../../shared.js';
import '../App.css';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
  
function Create() {
  const [changed, setChanged] = useState(false);
  const [newItem, setNewItem] = useState();
  var [catNoList, setCatNoList] = useState([]);
  const [catNoListLoaded, setCatNoListLoaded] = useState(false);
  var [newItemKey, setNewItemKey] = useState();

useEffect(()=> {
  console.log(changed);
  console.log('New Item ',newItem);
});

const [responseOpen, setResponseOpen] = React.useState(false);
const [message, setMessage] = useState('');

                               
useEffect(()=> {
  if (localStorage.getItem('adminFunctions') === '1' ) {
    
  } else {
    window.location.href='/'
  }
},[]);

const handleResponseClose = () => {
setResponseOpen(false);
window.location.href='/?item='+[newItem.itemNo]
};

const loadCatNoList = async () => {
    const url = baseUrl + 'catNo/list';
      await fetch(url, {
        method: 'GET',
        headers: {
            'username': localStorage.getItem('username'),
            'bToken': localStorage.getItem('bToken')
        }})
        .then((res) => {
          return res.json();
      }).then((json) => {
          setCatNoList(json);
          console.log('CatNo List Loaded ', catNoList)
      })
      .catch((err) => {
        setCatNoListLoaded(false);
         console.log(err.message);
      });
    }
          
    useEffect(() => {
      if (!catNoListLoaded ) {
        setCatNoListLoaded(true)
        loadCatNoList()
      }
    })

async function createItem(e) {
  e.preventDefault();
  const url = baseUrl;
  await fetch(url, {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
          'username': localStorage.getItem('username'),
          'bToken': localStorage.getItem('bToken')
      },
      body: JSON.stringify(newItem),
  })
      .then((response) => response.json())
        .then((data) => {
          console.log(data)
          if( data.status === 200) {
          setMessage(data.message);
          setResponseOpen(true);
          } else if (data.status === 401) {
            setMessage(data.message);
            setResponseOpen(true);
          }
        })
        .catch((err) => {
          setMessage(err.message);
          setResponseOpen(true);
        });
}

return (
    <div>
      <React.Fragment>
      <Dialog
        open={responseOpen}
        onClose={handleResponseClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="confirmResponseTitle">
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="confirmResponseDesc">
            [{message}]
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleResponseClose}>OK</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>

    <div className="createItem"><Box 
          margin="dense">
          <p style={{fontWeight: "bolder", fontSize: "20px", color: "darkorange"}}>Create New Item</p>
          <p style={{fontSize: "15px"}}> Please fill out all below fields to<br />create a new Item in the System.</p>
           
         <TextField 
        fullWidth
          margin="dense"
          id="itemNo" 
          required
          label="Item Number" 
          variant="outlined" 
          type="text" 
          onChange={(e) => {
            setChanged(true);
            setNewItem({
               ...newItem,
            itemNo: e.target.value,
            });
            }}
        />
         <TextField 
        fullWidth
          margin="dense"
          id="Name" 
          required
          label="Item Name" 
          variant="outlined" 
          type="text" 
          onChange={(e) => {
            setChanged(true);
            setNewItem({
               ...newItem,
            name: e.target.value,
            });
            }}
        />
        <FormControl
             margin="dense"
        fullWidth>
        <Autocomplete
        required
     disablePortal
     disableClearable
     id={newItemKey}
     options={catNoList}
      onChange={(e) => {
        setChanged(true);
        setNewItem({
           ...newItem,
        linkedCatNo: e.target.innerHTML,
        });
       setNewItemKey(`${Math.floor((Math.random() * 1000))}-min`);
       }}
       renderInput={(params) => <TextField {...params} label="Linked CatNo" />}
       />
     </FormControl>
     
{changed && newItem.itemNo && newItem.name && newItem.linkedCatNo ? ( <form
                        className="w-full max-w-sm"
                        id="item"
                        onClick={createItem}
                    >
                        <div className="mb-2">
                            <Button variant="contained"
                                form="item"
                            >
                                Create Item
                            </Button>
                        </div>
                        </form>
                        
                    ) : null
                  }    
                  <div className="mb-2">
                  <Button variant="contained"
                      onClick={(e) => {
                          window.location.href='/'
                      }}
                  >
                      Cancel
                  </Button>
              </div>
              </Box>
<br></br>
        </div>
  </div>
)};
       
export default Create