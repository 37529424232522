import React, { useState } from 'react';
import DensityMediumIcon from '@mui/icons-material/DensityMedium';
import CloseIcon from '@mui/icons-material/Close';
import { Link } from 'react-router-dom';
import './navBar.css';
import { IconContext } from 'react-icons';
import { ViewInAr, AddCircle } from '@mui/icons-material';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';

function Navbar() {
  const [sidebar, setSidebar] = useState(false);

  const showSidebar = () => setSidebar(!sidebar);

  return (
    <>
    {localStorage.getItem('username') !== null ? (
      <IconContext.Provider value={{ color: '#fff' }}>
        <div className='navbar'>
          <Link to='#' className='menu-bars'>
            <DensityMediumIcon  onClick= {showSidebar}/>
          </Link>
        </div>
        <nav className={sidebar ? 'nav-menu active' : 'nav-menu'}>
          <ul className='nav-menu-items' onClick={showSidebar}>
            <li className='navbar-toggle'>
              <Link to='#' className='menu-bars'>
                <CloseIcon />
              </Link>
            </li>
            { localStorage.getItem('adminFunctions') === '1' ? (
            <li className='nav-text'>
              <Link to='/' className='nav-text'>
              <ViewInAr />
              <span>Item View</span>
              </Link>
            </li>
            ): null}
            { localStorage.getItem('adminFunctions') === '1' ? (
              <li className='nav-text'>
              <Link to='/createItem' className='nav-text'>
              <AddCircle />
              <span>Create Item</span>
              </Link>
              </li>
            ): null}
            { localStorage.getItem('adminFunctions') === '1' ? (
              <li className='nav-text'>
              <Link to='/admin' className='nav-text'>
              <AdminPanelSettingsIcon />
              <span>Admin</span>
              </Link>
              </li>
            ): null}
            
          </ul>
        </nav>
      </IconContext.Provider>
): <div className='navbar' />}
    </>
  );
}

export default Navbar;
