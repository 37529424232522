import React, { useState, useEffect } from "react";
import { baseUrl } from '../../shared.js';
import '../App.css';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import Dialog from '@mui/material/Dialog';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { DateRangePicker  } from "@mui/x-date-pickers-pro";
import { SingleInputDateRangeField } from "@mui/x-date-pickers-pro";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import moment from "moment";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
  
function CreateBooking({bookingItem}) {
  const [changed, setChanged] = useState(false);
  const [newBooking, setNewBooking] = useState({});
  var [userList, setUserList] = useState([]);
  var [statusList, setStatusList] = useState([]);
  const [usersLoaded, setusersLoaded] = useState(false);
  const [statusLoaded, setStatusLoaded] = useState(false);
  var [bookingDatesList, setBookingDatesList] = useState([]);
  const [bookingDatesLoaded, setBookingDatesLoaded] = useState(false);
  var [bookingDatesKey, setBookingDatesKey] = useState();
  

useEffect(()=> {
  console.log(changed);
  console.log('New Booking ',newBooking);
});

const [responseOpen, setResponseOpen] = React.useState(false);
const [message, setMessage] = useState('');
const [hyperlink, setHyperLink] = useState('');

                               
useEffect(()=> {
  if (localStorage.getItem('adminFunctions') === '1' ) {
    
  } else {
    window.location.href='/'
  }
},[]);

const handleResponseClose = () => {
setResponseOpen(false);
window.location.href='/?item='+[newBooking.itemNo]
};

useEffect(() => {
  if (usersLoaded === false ) {
  const url = baseUrl + 'user/list/';
    fetch(url, {
      method: 'GET',
      headers: {
          'username': localStorage.getItem('username'),
          'bToken': localStorage.getItem('bToken')
      }})
      .then((res) => {
        return res.json();
    }).then((json) => {
        console.log(json)
        setUserList(json);
        setusersLoaded(true);
    })
    .catch((err) => {
       console.log(err.message);
    });
  }
  })

  useEffect(() => {
    if (statusLoaded === false ) {
    const url = baseUrl + 'status/list/';
      fetch(url, {
        method: 'GET',
        headers: {
            'username': localStorage.getItem('username'),
            'bToken': localStorage.getItem('bToken')
        }})
        .then((res) => {
          return res.json();
      }).then((json) => {
          console.log(json)
          setStatusList(json);
          setStatusLoaded(true);
      })
      .catch((err) => {
         console.log(err.message);
      });
    }
    })

    useEffect(() => {
      if (bookingDatesLoaded === false ) {
      loadBookingDates()
      setBookingDatesLoaded(true)
      }
    })

    const loadBookingDates = async () => {
      const url = baseUrl + 'bookingDates/list/';
        await fetch(url, {
          method: 'GET',
          headers: {
              'username': localStorage.getItem('username'),
              'bToken': localStorage.getItem('bToken'),
              'bookingItem': bookingItem,
          }})
          .then((res) => {
            return res.json();
        }).then((json) => {
            setBookingDatesList(json[0].dates);
            console.log('there',json[0].dates)
            setBookingDatesKey(`${Math.floor((Math.random() * 1000))}-min`);
            console.log('here',bookingDatesList)
        })
        .catch((err) => {
           console.log(err.message);
           setBookingDatesLoaded(false)
        });
    }


async function createBooking(e) {
  e.preventDefault();
  const url = baseUrl + 'hire/';
  await fetch(url, {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
          'username': localStorage.getItem('username'),
          'bToken': localStorage.getItem('bToken')
      },
      body: JSON.stringify(newBooking),
  })
      .then((response) => response.json())
        .then((data) => {
          console.log(data)
          if( data.status === 200) {
          setMessage(data.message);
          setHyperLink(data.hyperlink)
          setResponseOpen(true);
          } else if (data.status === 401) {
            setMessage(data.message);
            setResponseOpen(true);
          }
        })
        .catch((err) => {
          setMessage(err.message);
          setResponseOpen(true);
        });
}

return (
    <div>
      <React.Fragment>
      <Dialog
        open={responseOpen}
        onClose={handleResponseClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="confirmResponseTitle">
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="confirmResponseDesc">
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={(e) => {window.location.href=hyperlink}}>Go To Booking</Button>
          <Button onClick={handleResponseClose}>OK</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>

    <div className="createItem">
    <p style={{fontWeight: "bolder", fontSize: "20px", color: "darkorange"}}>New Booking</p>
    <p style={{fontSize: "15px"}}> Please fill out all below fields to<br />create a new Booking in the System.</p>
     <Box 
          margin="dense">
         <TextField 
        fullWidth
          margin="dense"
          id="itemNo" 
          InputProps={{
            readOnly: true,
          }}
          defaultValue={bookingItem}
          label="Item Number" 
          variant="outlined" 
          type="text" 
        />
        <FormControl
        margin="dense"
   fullWidth>
        <InputLabel shrink htmlFor="userNameTitle">
        Booked User
     </InputLabel>
        <Select
          native
          label="Booked User"
          inputProps={{
            id: 'usernameTitle',
          }}
          fullWidth
          onChange={(e) => {
            setChanged(true);
            setNewBooking({
              ...newBooking,
              itemNo: bookingItem,
              username: e.target.value,
           });
            }}>
            <option selected disabled>Select Booking User</option>
          {
          userList.map((userList) => {
          return <option value={userList.username} key={userList.id}>{userList.username}</option>
        })
      }
  </Select>
  </FormControl>
  <FormControl
   margin="dense"
   fullWidth>
  <LocalizationProvider dateAdapter={AdapterDayjs}>
<DateRangePicker
  slots={{ field: SingleInputDateRangeField }}
  slotProps={{ textField: { fullWidth: true } }}
  name="allowedRange"
  label="Booking Dates"
  disablePast
  shouldDisableDate={(date) => {
    const fullDate = date.date()+'-'+(date.month()+1)+'-'+date.year();
    return bookingDatesList.includes(fullDate) ;
  }}
  format="DD-MM-YYYY"
  onChange={(e) => {
    console.log(e)
    console.log((e[0] === null ) ? '':moment(e[0].$d).format('YYYY-MM-DD'))
    console.log((e[1] === null ) ? '':moment(e[1].$d).format('YYYY-MM-DD'))
    setNewBooking({
       ...newBooking,
       itemNo: bookingItem, 
       startDate: (e[0] === null ) ? '':moment(e[0].$d).format('YYYY-MM-DD'),
       returnDate: (e[1] === null ) ? '':moment(e[1].$d).format('YYYY-MM-DD'),
    });
  }}
/>
</LocalizationProvider>
</FormControl>
              <FormControl
              margin="dense"
         fullWidth>
              <InputLabel shrink htmlFor="bookingStatusTitle">
             Booking Status
           </InputLabel>
              <Select
                native
                label="bookingStatus"
                inputProps={{
                  id: 'bookingStatusTitle',
                }}
                fullWidth
                onChange={(e) => {
                  setChanged(true);
                  setNewBooking({
                    ...newBooking,
                    itemNo: bookingItem,
                    status: e.target.value,
                 });
                  }}>
                  <option selected disabled>Select Booking Status</option>
                {
                statusList.map((statusList) => {
                return <option value={statusList.id} key={statusList.id}>{statusList.status}</option>
              })
            }
        </Select>
        </FormControl>
        <TextField 
       fullWidth
         margin="dense"
         id="poNumber" 
         onChange={(e) => {
           setChanged(true);
           setNewBooking({
             ...newBooking,
             itemNo: bookingItem,
             poNumber: e.target.value,
          })}}
         label="Purchase Order Number" 
         variant="outlined" 
         type="text" 
       />
       <TextField 
      fullWidth
        margin="dense"
        id="invoiceNumber" 
        onChange={(e) => {
          setChanged(true);
          setNewBooking({
            ...newBooking,
            itemNo: bookingItem,
            invoiceNumber: e.target.value,
         })}}
        label="Invoice Number" 
        variant="outlined" 
        type="text" 
      />
     </Box>
                        <div className="mb-2">
                            <Button variant="contained"
                                form="item"
                                onClick={createBooking}
                                disabled={!newBooking.itemNo || !newBooking.username || !newBooking.startDate || !newBooking.returnDate|| !newBooking.status}
                            >
                                Create Booking
                            </Button><br />
                  <Button variant="contained"
                      onClick={(e) => {
                          window.location.href='/?item='+[bookingItem]
                      }}
                  >
                      Cancel
                  </Button>
                        </div>
<br></br>
        </div>
  </div>
)};
       
export default CreateBooking