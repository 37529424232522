import React, { useState } from 'react';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Link } from 'react-router-dom';
import { userBarData } from './userBarData';
import './userBar.css';
import { IconContext } from 'react-icons';

function UserNavBar() {
  const [userBar, setUserBar] = useState(false);

  const showUserBar = () => setUserBar(!userBar);

  return (
    <>
      {localStorage.getItem('username') !== null ? (
      <IconContext.Provider value={{ color: '#fff' }}>
        <div className='userbar'>
          <Link to='#' className='userMenu-bars'>
            <AccountCircleIcon onClick={showUserBar} />
          </Link>
        </div>
        <nav className={userBar ? 'user-menu active' : 'user-menu'}>
          <ul className='user-menu-items' onClick={showUserBar}>
            <li className='userbar-toggle'>
              <Link to='#' className='userMenu-bars'>
                <AccountCircleIcon />
              </Link>
            </li>
            { localStorage.getItem('fullName') !== null ? (
              <li className='user-text-small'>
              <Link to=''>
                <AccountCircleIcon />
              <span>Welcome back {localStorage.getItem('fullName').replace(/"/g, '')}</span>
              </Link>
              </li>
            ): null}
            {userBarData.map((item, index) => {
              return (
                <li key={index} className={item.cName}>
                  <Link to={item.path}>
                    {item.icon}
                    <span>{item.title}</span>
                  </Link>
                </li>
              );
            })}
          </ul>
        </nav>
      </IconContext.Provider>
): <div className='userbar' />}
    </>
  );
}

export default UserNavBar;
