import React, { useState, useEffect } from "react";
import { baseUrl } from '../../shared.js';
import '../App.css';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import Dialog from '@mui/material/Dialog';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { DateRangePicker  } from "@mui/x-date-pickers-pro";
import { SingleInputDateRangeField } from "@mui/x-date-pickers-pro";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from "dayjs";
import moment from "moment";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
  
function EditBooking({bookingItem}) {
  const [changed, setChanged] = useState(false);
  const [modBooking, setModBooking] = useState(bookingItem);
  var [statusList, setStatusList] = useState([]);
  const [statusLoaded, setStatusLoaded] = useState(false);
  var [bookingDatesList, setBookingDatesList] = useState([]);
  const [bookingDatesLoaded, setBookingDatesLoaded] = useState(false);
  const [open, setOpen] = React.useState(false);
  var [bookingDatesKey, setBookingDatesKey] = useState();
 

  const handleClickOpen = () => {
    setOpen(true);
  }; 

  const handleClose = () => {
    setOpen(false);
  };

useEffect(()=> {
  console.log(changed);
  console.log('Mod Booking ',modBooking);
});

const [responseOpen, setResponseOpen] = React.useState(false);
const [message, setMessage] = useState('');
const [hyperlink, setHyperLink] = useState('');

                               
useEffect(()=> {
  if (localStorage.getItem('adminFunctions') === '1' ) {
    
  } else {
    window.location.href='/'
  }
},[]);

const handleResponseClose = () => {
setResponseOpen(false);
window.location.href='/?item='+[modBooking.itemNo]
};

  useEffect(() => {
    if (statusLoaded === false ) {
    const url = baseUrl + 'status/list/';
      fetch(url, {
        method: 'GET',
        headers: {
            'username': localStorage.getItem('username'),
            'bToken': localStorage.getItem('bToken')
        }})
        .then((res) => {
          return res.json();
      }).then((json) => {
          console.log(json)
          setStatusList(json);
          setStatusLoaded(true);
      })
      .catch((err) => {
         console.log(err.message);
      });
    }
    })

    useEffect(() => {
      if (bookingDatesLoaded === false ) {
      loadBookingDates()
      setBookingDatesLoaded(true)
      }
    })

    const loadBookingDates = async () => {
      const url = baseUrl + 'bookingDates/list/';
        await fetch(url, {
          method: 'GET',
          headers: {
              'username': localStorage.getItem('username'),
              'bToken': localStorage.getItem('bToken'),
              'bookingItem': modBooking.itemNo,
          }})
          .then((res) => {
            return res.json();
        }).then((json) => {
            setBookingDatesList(json[0].dates);
            console.log('there',json[0].dates)
            setBookingDatesKey(`${Math.floor((Math.random() * 1000))}-min`);
            console.log('here',bookingDatesList)
        })
        .catch((err) => {
           console.log(err.message);
           setBookingDatesLoaded(false)
        });
    }

async function editBooking(e) {
  e.preventDefault();
  const url = baseUrl + 'hire/edit';
  await fetch(url, {
      method: 'PATCH',
      headers: {
          'Content-Type': 'application/json',
          'username': localStorage.getItem('username'),
          'bToken': localStorage.getItem('bToken')
      },
      body: JSON.stringify(modBooking),
  })
      .then((response) => response.json())
        .then((data) => {
          console.log(data)
          if( data.status === 200) {
          setMessage(data.message);
          setHyperLink(data.hyperlink)
          setResponseOpen(true);
          } else if (data.status === 401) {
            setMessage(data.message);
            setResponseOpen(true);
          }
        })
        .catch((err) => {
          setMessage(err.message);
          setResponseOpen(true);
        });
}

async function deleteBooking(e) {
  e.preventDefault();
  const url = baseUrl + 'hire/delete';
  await fetch(url, {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
          'username': localStorage.getItem('username'),
          'bToken': localStorage.getItem('bToken')
      },
      body: JSON.stringify(modBooking),
  })
  .then((data) => {
    window.location.href='/?item='+modBooking.itemNo
        });
}

return (
    <div>
    <React.Fragment>
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="confirmDeleteTitle">
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="confirmDeleteDesc">
          Are you sure you want to delete the Booking?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={deleteBooking}>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  </React.Fragment>
      <React.Fragment>
      <Dialog
        open={responseOpen}
        onClose={handleResponseClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="confirmResponseTitle">
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="confirmResponseDesc">
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={(e) => {window.location.href=hyperlink}}>Go To Booking</Button>
          <Button onClick={handleResponseClose}>OK</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
    <div className="createItem">
    <p style={{fontWeight: "bolder", fontSize: "20px", color: "darkorange"}}>Edit Booking</p>
    <p style={{fontSize: "15px"}}> Update below fields to<br />edit the Booking in the System.</p>
     <Box 
          margin="dense">
          <TextField 
         fullWidth
           margin="dense"
           id="bookingId" 
           InputProps={{
             readOnly: true,
           }}
           defaultValue={modBooking.id}
           label="bookingId" 
           variant="outlined" 
           type="text" 
         />
         <TextField 
        fullWidth
          margin="dense"
          id="itemNo" 
          InputProps={{
            readOnly: true,
          }}
          defaultValue={modBooking.itemNo}
          label="Item Number" 
          variant="outlined" 
          type="text" 
        />
        <TextField 
       fullWidth
         margin="dense"
         id="company" 
         InputProps={{
           readOnly: true,
         }}
         defaultValue={modBooking.company}
         label="Company" 
         variant="outlined" 
         type="text" 
       />
              <FormControl
               margin="dense"
               fullWidth>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateRangePicker
              slots={{ field: SingleInputDateRangeField }}
              slotProps={{ textField: { fullWidth: true } }}
              key={bookingDatesKey}
              name="allowedRange"
              label="Booking Dates"
              defaultValue={[dayjs(modBooking.SD), dayjs(modBooking.RD)]}
              shouldDisableDate={(date) => {
                const fullDate = date.date()+'-'+(date.month()+1)+'-'+date.year();
                return bookingDatesList.includes(fullDate) ;
              }}
              format="DD-MM-YYYY"
              onChange={(e) => {
                setModBooking({
                   ...modBooking,
                   itemNo: bookingItem, 
                   SD: (e[0] === null ) ? '':moment(e[0].$d).format('YYYY-MM-DD'),
                   RD: (e[1] === null ) ? '':moment(e[1].$d).format('YYYY-MM-DD'),
                });
              }}
            />
            </LocalizationProvider>
            </FormControl>
              <FormControl
              margin="dense"
         fullWidth>
              <InputLabel shrink htmlFor="bookingStatusTitle">
             Booking Status
           </InputLabel>
              <Select
                native
                label="bookingStatus"
                inputProps={{
                  id: 'bookingStatusTitle',
                }}
                fullWidth
                onChange={(e) => {
                  setChanged(true);
                  setModBooking({
                    ...modBooking,
                    statusId: e.target.value,
                 });
                  }}>
                  <option selected disabled>Current: {modBooking.status}</option>
                {
                statusList.map((statusList) => {
                return <option value={statusList.id} key={statusList.id}>{statusList.status}</option>
              })
            }
        </Select>
        </FormControl>
        <TextField 
       fullWidth
         margin="dense"
         id="poNumber" 
         defaultValue={modBooking.poNumber}
         onChange={(e) => {
           setChanged(true);
           setModBooking({
             ...modBooking,
             poNumber: e.target.value,
          })}}
         label="Purchase Order Number" 
         variant="outlined" 
         type="text" 
       />
       <TextField 
      fullWidth
        margin="dense"
        id="invoiceNumber" 
        defaultValue={modBooking.invoiceNumber}
        onChange={(e) => {
          setChanged(true);
          setModBooking({
            ...modBooking,
            invoiceNumber: e.target.value,
         })}}
        label="Invoice Number" 
        variant="outlined" 
        type="text" 
      />
     </Box>
                        <div className="mb-2">
                            <Button variant="contained"
                                form="item"
                                onClick={editBooking}
                                disabled={ !modBooking.SD || !modBooking.RD|| !modBooking.status }
                            >
                                Update Booking
                            </Button><br />
                            <Button variant="contained"
                                form="item"
                                onClick={handleClickOpen}
                            >
                                Delete Booking
                            </Button><br />
                  <Button variant="contained"
                      onClick={(e) => {
                          window.location.href='/?booking='+[bookingItem.id]
                      }}
                  >
                      Cancel
                  </Button>
                        </div>
<br></br>
        </div>
  </div>
)};
       
export default EditBooking